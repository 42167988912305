import store from '@/services/store'

let updateCount = 0

export default {
  updateImage: ({
    id,
    src, // image source
    position,
    ...rest
  }) => store.commit('video/findAndUpdateElement', {
    find: { by: 'name', value: 'ce-logo' },
    update: {
      ...id ? { id } : {},
      value: src,
      ...position ? { position } : {},
      meta: {
        style: {
          'z-index': 2
        }
      },
      ...rest
    }
  }),
  updateImagePosition: ({
    id,
    position,
    ...rest
  }) => store.commit('video/findAndUpdateElement', {
    find: { by: 'name', value: 'ce-logo' },
    update: {
      ...id ? { id } : {},
      ...position ? { position } : {},
      meta: {
        style: {
          'z-index': 2
        }
      },
      ...rest
    }
  }),
  updateVideo: ({
    id,
    src, // video source
    type, // video type eg: 'video/mp4'
    currentTime,
    showSubtitles,
    ...rest
  }, shouldUpdateCount = true) => {
    // set subtitles show state
    store.commit('video/findAndUpdateElement', {
      find: { by: 'name', value: 'ce-subtitle' },
      update: {
        hidden: !showSubtitles
      }
    })
    // video update
    store.commit('video/findAndUpdateElement', {
      find: { by: 'name', value: 'ce-video' },
      update: {
        id,
        value: src,
        type,
        currentTime,
        ...shouldUpdateCount ? { updateCount: ++updateCount } : {},
        ...rest
      }
    })
  },
  updateSubtitle: ({
    id,
    text, // subtitle
    style = {} // eg: { 'font-family': 'Roboto', 'color': '#E2462C', 'font-style': 'italic' }
  }) => store.commit('video/findAndUpdateElement', {
    find: { by: 'name', value: 'ce-subtitle' },
    update: {
      id,
      value: text,
      meta: {
        style: {
          'bottom': '24px',
          'padding': '4px 8px',
          'background': '#FFF',
          'border-radius': '5px',
          'display': 'flex',
          'align-items': 'center',
          'justify-content': 'center',
          'z-index': 2,
          'width': '80%',
          'text-align': 'center',

          'font-family': 'Roboto',
          'font-size': '12px',
          'line-height': '14px',
          'font-weight': '400', // 700
          'font-style': 'normal', // italic
          'color': '#E2462C',
          ...style
        }
      }
    }
  }),
  updateHeading: ({
    text
  }) => store.commit('video/findAndUpdateElement', {
    find: { by: 'name', value: 'ag-heading' },
    update: {
      value: text
    }
  })
}
