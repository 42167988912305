export default (scale, { accentColor, backgroundColor, enableLogo }) => ({
  'default': {
    'landscape': {
      'ag-node-1': {
        size: {
          height: '100%',
          width: `60%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        }
      },

      'ag-heading': {
        size: {
          width: `50%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'font-size': `${76 * scale}px`,
          'line-height': `${89 * scale}px`,
          top: `5.5%`,
          left: `5.2%`
        }
      },

      'ag-wave': {
        size: {
          height: '16.5%',
          width: '26%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: '11%',
          left: '5.2%',
          overflow: 'hidden'
        }
      },

      'ce-subtitle': {
        size: {
          width: `40%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: '30%',
          left: '5.2%'
        }
      },

      'ag-node-11': {
        size: {
          height: '100%',
          width: '40%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 60,
          y: 0
        }
      },
      'ag-node-12': {
        size: {
          height: `${350 * scale}px`, // commenting out fixes weird aspect ratio issues
          width: `${350 * scale}px`
        },
        position: { // in percentage & relative from left bottom corner
          x: 60,
          y: 50
        }
      }
    },

    square: {
      'ag-node-1': {
        size: {
          height: '67%',
          width: `100%`
        },
        style: {
          'bottom': 0
        }
      },

      'ag-heading': {
        size: {},
        style: {
          width: '64%',
          'font-size': `${56 * scale}px`,
          'text-align': 'center',
          top: '48%',
          left: '18%',
          'align-items': 'center'
        }
      },

      'ag-wave': {
        size: {
          height: '9.25%',
          width: '26%'
        },
        style: {
          bottom: '6.5%',
          left: '37%',
          overflow: 'hidden'
        }
      },

      'ce-subtitle': {
        size: { width: '72%' },
        style: {
          width: '72%',
          bottom: '20%',
          left: '14%',
          top: 'unset',
          'text-align': 'center'
        }
      },

      'ag-node-11': {
        size: {
          height: '33%',
          width: '100%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 67
        }
      },
      'ag-node-12': {
        size: {
          height: '27.77%', // commenting out fixes weird aspect ratio issues
          width: '27.77%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 50,
          y: 67
        }
      }
    },

    portrait: {
      'ag-node-1': {
        size: {
          height: '63%',
          width: `100%`
        },
        style: {
          'bottom': 0
        }
      },

      'ag-heading': {
        size: {
          width: `${900 * scale}px`
        },
        style: {
          'font-size': `${76 * scale}px`,
          'text-align': 'center',
          top: `${900 * scale}px`,
          left: `${90 * scale}px`,
          'align-items': 'center'
        }
      },

      'ag-wave': {
        size: {
          height: '10%',
          width: '50%'
        },
        style: {
          bottom: `${117 * scale}px`,
          left: `${294 * scale}px`,
          overflow: 'hidden'
        }
      },

      'ce-subtitle': {
        size: {
          width: `${780 * scale}px`
        },
        style: {
          bottom: `${420 * scale}px`,
          left: `${150 * scale}px`,
          'text-align': 'center'
        }
      },

      'ag-node-11': {
        size: {
          height: '37%',
          width: '100%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 63
        }
      },
      'ag-node-12': {
        size: {
          height: `${350 * scale}px`, // commenting out fixes weird aspect ratio issues
          width: `${350 * scale}px`
        },
        position: { // in percentage & relative from left bottom corner
          x: 50,
          y: 63
        }
      }
    }
  },

  'one': {
    'landscape': {
      'ag-node-1': {
        style: {
          opacity: 0
        }
      },

      'ag-heading': {
        size: {
          width: `45%`,
          height: '25%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'font-size': `${64 * scale}px`,
          'line-height': `${72 * scale}px`,
          'justify-content': 'center',
          top: enableLogo ? '33%' : '22.3%',
          left: `47.3%`
        }
      },

      'ag-wave': {
        size: {
          height: '4.7%',
          width: '100%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: '8%',
          left: '0',
          overflow: 'hidden'
        },
        waveType: 'one'
      },

      'ce-subtitle': {
        value: 'Subtitles which are auto-filled from the transcript. It can extend to up to 3 lines.',
        size: {
          width: `45%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: enableLogo ? '58%' : '47.3%',
          left: '47.3%',
          'font-size': '48px'
        }
      },

      'ag-node-11': {},
      'ag-node-12': {
        size: {
          height: '65.8%', // commenting out fixes weird aspect ratio issues
          width: '37%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '10.7%',
          left: '6%'
        }
      },
      'ag-node-13': {
        size: {
          height: `16.7%`, // commenting out fixes weird aspect ratio issues
          width: `9.4%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '15.2%',
          left: '47.3%'
        }
      }
    },

    square: {
      'ag-node-0': {
        style: {
          'background': '#fff',
          'background-image': `url(/images/default/template-one-bg.png)`,
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
          'background-position': 'center'
        }
      },
      'ag-node-1': {
        style: {
          opacity: 0
        }
      },

      'ag-heading': {
        size: {
          width: `86.5%`,
          height: '13%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'font-size': `${60 * scale}px`,
          'line-height': `${70 * scale}px`,
          'justify-content': 'center',
          top: `62%`,
          left: `5.8%`,
          'text-align': 'center'
        }
      },

      'ag-wave': {
        size: {
          height: '4.7%',
          width: '100%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: '0',
          left: '0',
          overflow: 'hidden'
        },
        waveType: 'one'
      },

      'ce-subtitle': {
        value: 'Subtitles which are auto-filled from the transcript. It can extend to up to 3 lines.',
        size: {
          width: `86.5%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '75%',
          left: '5.8%',
          'font-size': '48px',
          'text-align': 'center'
        }
      },

      'ag-node-11': {},
      'ag-node-12': {
        size: {
          height: `${400 * scale}px`, // commenting out fixes weird aspect ratio issues
          width: `${400 * scale}px`
        },
        position: { // in percentage & relative from left bottom corner
          x: 50,
          y: 66.1
        },
        style: {

        }
      },
      'ag-node-13': {
        size: {
          height: `${120 * scale}px`, // commenting out fixes weird aspect ratio issues
          width: `${120 * scale}px`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '6.75%',
          right: '6.75%'
        }
      }
    },

    portrait: {
      'ag-node-0': {
        style: {
          'background': '#fff',
          'background-image': `url(/images/default/template-one-bg.png)`,
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
          'background-position': 'center'
        }
      },
      'ag-node-1': {
        style: {
          opacity: 0
        }
      },

      'ag-heading': {
        size: {
          width: `80%`,
          height: '12%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'font-size': `${72 * scale}px`,
          'line-height': `${80 * scale}px`,
          'justify-content': 'center',
          top: enableLogo ? '70%' : '60%',
          left: `10%`
        }
      },

      'ag-wave': {
        size: {
          height: '6.3%',
          width: '100%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: '0',
          top: '0',
          left: '0',
          overflow: 'hidden'
        },
        waveType: 'one'
      },

      'ce-subtitle': {
        value: 'Subtitles which are auto-filled from the transcript. It can extend to up to 3 lines.',
        size: {
          width: `80%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: enableLogo ? '82.5%' : '73.1%',
          left: '10%',
          'font-size': '48px'
        }
      },

      'ag-node-11': {},
      'ag-node-12': {
        size: {
          height: `34.16%`, // commenting out fixes weird aspect ratio issues
          width: `60.7%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 50,
          y: 65.3
        },
        style: {}
      },
      'ag-node-13': {
        size: {
          height: `9.4%`, // commenting out fixes weird aspect ratio issues
          width: `16.7%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '58%',
          left: '10%'
        }
      }
    }
  },

  'two': {
    'landscape': {
      'ag-node-1': {
        style: {
          opacity: 0
        }
      },

      'ag-heading': {
        size: {
          width: `60%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'text-transform': 'uppercase',
          'font-size': `${56 * scale}px`,
          'line-height': `${72 * scale}px`,
          top: `8.9%`,
          left: enableLogo ? `17.7%` : '50%',
          ...(enableLogo ? {} : { transform: 'translateX(-50%)', 'text-align': 'center' })
        }
      },

      'ag-wave': {
        size: {
          height: '32.4%',
          width: '100%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: '32.7%',
          left: '0'
        },
        waveType: 'two'
      },

      'ce-subtitle': {
        value: 'Subtitles which are auto-filled from the transcript. It can extend to up to 2 lines.',
        size: {
          width: `68.7%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '79%',
          left: '15.7%',
          'font-size': '56px',
          'text-align': 'center'
        }
      },

      'ag-node-11': {},
      'ag-node-12': {
        size: {
          height: `41.11%`,
          width: `23.125%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 50,
          y: 48.9
        },
        style: {
          'z-index': 5
        }
      },
      'ag-node-13': {
        size: {
          height: `16.7%`,
          width: `9.4%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '7.4%',
          left: '5.9%'
        }
      }
    },

    square: {
      'ag-node-1': {
        style: {
          opacity: 0
        }
      },

      'ag-heading': {
        size: {
          width: `64.2%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'text-transform': 'uppercase',
          'font-size': `${52 * scale}px`,
          'line-height': `${57 * scale}px`,
          'text-align': 'right',
          top: `8.33%`,
          left: enableLogo ? `14.6%` : '50%',
          ...(enableLogo ? {} : { transform: 'translateX(-50%)', 'text-align': 'center' })
        }
      },

      'ag-wave': {
        size: {
          height: '19.6%',
          width: '100%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          left: '0',
          top: '40.2%'
        },
        waveType: 'two'
      },

      'ce-subtitle': {
        value: 'Subtitles which are auto-filled from the transcript. It can extend to up to 2 lines.',
        size: {
          width: `87%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '74%',
          left: '5.2%',
          'font-size': '48px',
          'text-align': 'center'
        }
      },

      'ag-node-11': {},
      'ag-node-12': {
        size: {
          height: `37%`,
          width: `37%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 50,
          y: 50
        },
        style: {
          'z-index': 5
        }
      },
      'ag-node-13': {
        size: {
          height: `11.1%`,
          width: `11.1%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '8.7%',
          right: '5.2%'
        }
      }
    },

    portrait: {
      'ag-node-0': {
        style: {
          border: `${48 * scale}px solid ${accentColor}`
        }
      },
      'ag-node-1': {
        style: {
          'z-index': 5,
          border: `${48 * scale}px solid ${accentColor}`,
          'border-radius': `${96 * scale}px`
        }
      },

      'ag-heading': {
        size: {
          width: `63.7%`,
          height: `9.4%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'text-transform': 'uppercase',
          'font-size': `${50 * scale}px`,
          'line-height': `${72 * scale}px`,
          display: 'flex',
          'justify-content': 'center',
          top: `7.34%`,
          left: enableLogo ? `24%` : '50%',
          ...(enableLogo ? {} : { transform: 'translateX(-50%)', 'text-align': 'center' })
        }
      },

      'ag-wave': {
        size: {
          height: '11%',
          width: '100%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          left: '0',
          top: '40%'
        },
        waveType: 'two'
      },

      'ce-subtitle': {
        value: 'Subtitles which are auto-filled from the transcript. It can extend to up to 2 lines.',
        size: {
          width: `80.6%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '63.5%',
          left: '9.7%',
          'font-size': '64px',
          'text-align': 'center'
        }
      },

      'ag-node-11': {},
      'ag-node-12': {
        size: {
          height: `29%`,
          width: `51.5%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 50,
          y: 55
        },
        style: {
          'z-index': 5
        }
      },
      'ag-node-13': {
        size: {
          height: `9.4%`,
          width: `16.7%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '7.34%',
          left: '6%'
        }
      }
    }
  },

  'three': {
    'landscape': {
      'ag-node-1': {
        style: {
          opacity: 0
        }
      },

      'ag-heading': {
        size: {
          width: `50%`,
          height: `18.5%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'font-size': `${72 * scale}px`,
          'line-height': `${80 * scale}px`,
          'justify-content': 'center',
          top: enableLogo ? `15.8%` : '27.8%',
          left: `50%`

        }
      },

      'ag-wave': {
        size: {
          height: `${2040 * scale}px`,
          width: `${2040 * scale}px`
        },
        position: { // in percentage & relative from left bottom corner
          x: 23.8,
          y: 42.31
        },
        cover: false,
        style: {},
        waveType: 'three'
      },

      'ce-subtitle': {
        value: 'Subtitles which are auto-filled from the transcript. It can extend to up to 3 lines.',
        size: {
          width: `45.5%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'z-index': 5,
          top: enableLogo ? '38.5%' : '50.5%',
          left: '50%',
          'font-size': '52px'
        }
      },

      'ag-node-11': {},
      'ag-node-12': {
        size: {
          height: `68.51%`,
          width: `38.54%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 23.8,
          y: 42.31
        },
        style: {
          'z-index': 5,
          'border-radius': `100%`
        }
      },
      'ag-node-13': {
        size: {
          height: `16.7%`,
          width: `9.4%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '76%',
          right: '6.35%',
          'background-image': `url(/images/default/logo-light.png)`
        }
      }
    },

    square: {
      'ag-node-1': {
        style: {
          opacity: 0
        }
      },

      'ag-heading': {
        size: {
          width: `81%`,
          height: `18.52%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'font-size': `${76 * scale}px`,
          'line-height': `${84 * scale}px`,
          top: `50%`,
          left: `7.4%`,
          'justify-content': 'center'
        }
      },

      'ag-wave': {
        size: {
          height: `94.4%`,
          width: `94.4%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 50,
          y: 75.5
        },
        cover: false,
        style: {},
        waveType: 'three'
      },

      'ce-subtitle': {
        value: 'Subtitles which are auto-filled from the transcript. It can extend to up to 3 lines.',
        size: {
          width: `66.6%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'z-index': 5,
          top: '72.2%',
          left: '7.4%',
          'font-size': '48px'
        }
      },

      'ag-node-11': {},
      'ag-node-12': {
        size: {
          height: `34.2%`,
          width: `34.2%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 50,
          y: 75.5
        },
        style: {
          'z-index': 5,
          'border-radius': `100%`
        }
      },
      'ag-node-13': {
        size: {
          height: `11.1%`,
          width: `11.1%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: '5.6%',
          right: '5.6%',
          'background-image': `url(/images/default/logo-light.png)`
        }
      }
    },

    portrait: {
      'ag-node-1': {
        style: {
          opacity: 0
        }
      },

      'ag-heading': {
        size: {
          width: `81%`,
          height: `10.4%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'font-size': `${80 * scale}px`,
          'line-height': `${90 * scale}px`,
          top: `60.6%`,
          left: `9.6%`,
          'justify-content': 'center'
        }
      },

      'ag-wave': {
        size: {
          height: `106.25%`,
          width: `188.8%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 50,
          y: 70.2
        },
        cover: false,
        style: {},
        waveType: 'three'
      },

      'ce-subtitle': {
        value: 'Subtitles which are auto-filled from the transcript. It can extend to up to 3 lines.',
        size: {
          width: `81%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'z-index': 5,
          top: '73%',
          left: '9.6%',
          'font-size': '52px'
        }
      },

      'ag-node-11': {},
      'ag-node-12': {
        size: {
          height: `38.54%`,
          width: `68.51%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 50,
          y: 70.2
        },
        style: {
          'z-index': 5,
          'border-radius': `100%`
        }
      },
      'ag-node-13': {
        size: {
          height: `7.81%`,
          width: `13.9%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: '4.2%',
          right: '8%',
          'background-image': `url(/images/default/logo-light.png)`
        }
      }
    }
  },

  'four': {
    'landscape': {
      'ag-node-1': {
        style: {
          opacity: 0
        }
      },

      'ag-heading': {
        size: {
          width: `70%`,
          height: '15.6%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'text-transform': 'uppercase',
          'font-size': `${72 * scale}px`,
          'line-height': `${76 * scale}px`,
          'justify-content': 'center',
          top: `8.14%`,
          left: enableLogo ? `17.7%` : '50%',
          ...(enableLogo ? {} : { transform: 'translateX(-50%)', 'text-align': 'center' })

        }
      },

      'ag-wave': {
        size: {
          height: '35%',
          width: '100%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: '0',
          left: '0',
          'display': 'flex',
          'flex-direction': 'column',
          'justify-content': 'flex-end'
        },
        waveType: 'four'
      },

      'ce-subtitle': {
        value: 'Subtitles which are auto-filled from the transcript. It can extend to up to 2 lines.',
        size: {
          width: `87.5%`,
          height: `33.7%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '30.8%',
          left: '6.1%',
          'font-size': `72px`,
          'line-height': '1.2',
          'text-align': 'center',
          'display': 'flex',
          'align-items': 'center',
          'justify-content': 'center'
        }
      },

      'ag-node-11': {},
      'ag-node-12': {
        size: {
          height: `0`,
          width: `0`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'opacity': '0'
        }
      },
      'ag-node-13': {
        size: {
          height: `16.7%`,
          width: `9.4%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '7.7%',
          left: '6.1%'
        }
      }
    },

    square: {
      'ag-node-1': {
        style: {
          opacity: 0
        }
      },

      'ag-heading': {
        size: {
          width: `76%`,
          height: '11.5%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'text-transform': 'uppercase',
          'font-size': `${52 * scale}px`,
          'line-height': `${57 * scale}px`,
          'justify-content': 'center',
          top: `5.75%`,
          left: enableLogo ? `18%` : '50%',
          ...(enableLogo ? {} : { transform: 'translateX(-50%)', 'text-align': 'center' })

        }
      },

      'ag-wave': {
        size: {
          height: '29%',
          width: '160%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: '0',
          left: '0',
          'display': 'flex',
          'flex-direction': 'column',
          'justify-content': 'flex-end'
        },
        waveType: 'four'
      },

      'ce-subtitle': {
        value: 'Subtitles which are auto-filled from the transcript. It can extend to up to 2 lines.',
        size: {
          width: `79.5%`,
          height: `25.9%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '32.4%',
          left: '9.8%',
          'font-size': '72px',
          'line-height': '1.2',
          'text-align': 'left'
        }
      },

      'ag-node-11': {},
      'ag-node-12': {
        size: {
          height: `0`,
          width: `0`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'opacity': '0'
        }
      },
      'ag-node-13': {
        size: {
          height: `11.1%`,
          width: `11.1%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '5.75%',
          left: '5.75%'
        }
      }
    },

    portrait: {
      'ag-node-1': {
        style: {
          opacity: 0
        }
      },

      'ag-heading': {
        size: {
          width: enableLogo ? `63.9%` : '72%',
          height: '7.3%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'text-transform': 'uppercase',
          'font-size': `${56 * scale}px`,
          'line-height': `${60 * scale}px`,
          'display': 'flex',
          'justify-content': 'center',
          top: `8.95%`,
          left: enableLogo ? `30%` : '50%',
          ...(enableLogo ? {} : { transform: 'translateX(-50%)', 'text-align': 'center' })
        }
      },

      'ag-wave': {
        size: {
          height: '30%',
          width: '200%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: '0',
          left: '0',
          'display': 'flex',
          'flex-direction': 'column',
          'justify-content': 'flex-end'
        },
        waveType: 'four'
      },

      'ce-subtitle': {
        value: 'Subtitles which are auto-filled from the transcript. It can extend to up to 2 lines.',
        size: {
          width: `81.5%`,
          height: `31.25%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '29.7%',
          left: '9.25%',
          'font-size': '72px',
          'line-height': '1.2',
          'display': 'flex',
          'align-items': 'center',
          'justify-content': 'center',
          'text-align': 'center'
        }
      },

      'ag-node-11': {},
      'ag-node-12': {
        size: {
          height: `0`,
          width: `0`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'opacity': '0'
        }
      },
      'ag-node-13': {
        size: {
          height: `9.4%`,
          width: `16.7%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '7.9%',
          left: '8.7%'
        }
      }
    }
  },

  'five': {
    'landscape': {
      'ag-node-1': {
        size: {
          height: '30.2%',
          width: `100%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        cover: true,
        style: {
          'z-index': 3,
          top: 0,
          left: 0,
          background: accentColor || '#E55841'
        }
      },

      'ag-heading': {
        size: {
          width: `70%`,
          height: '15.6%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'text-transform': 'uppercase',
          'font-size': `${72 * scale}px`,
          'line-height': `${80 * scale}px`,
          'justify-content': 'center',
          top: `8.14%`,
          left: enableLogo ? `18.2%` : '50%',
          ...(enableLogo ? {} : { transform: 'translateX(-50%)', 'text-align': 'center' })
        }
      },

      'ag-wave': {
        size: {
          height: '24.5%',
          width: '100%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: '0',
          left: '0',
          'display': 'flex',
          'flex-direction': 'column',
          'justify-content': 'flex-end'
        },
        waveType: 'five'
      },

      'ce-subtitle': {
        value: 'Subtitles which are auto-filled from the transcript. It can extend to up to 2 lines.',
        size: {
          width: `87.5%`,
          height: `33.7%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '36.4%',
          left: '6.1%',
          'font-size': '72px',
          'line-height': '1.2',
          'text-align': 'center',
          'display': 'flex',
          'align-items': 'center',
          'justify-content': 'center'
        }
      },

      'ag-node-11': {},
      'ag-node-12': {
        size: {
          height: `0`,
          width: `0`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'opacity': '0'
        }
      },
      'ag-node-13': {
        size: {
          height: `16.7%`,
          width: `9.4%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '7.7%',
          left: '6.1%'
        }
      }
    },

    square: {
      'ag-node-1': {
        size: {
          height: '28.1%',
          width: `100%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        cover: true,
        style: {
          'z-index': 3,
          top: 0,
          left: 0,
          background: accentColor || '#E55841'
        }
      },

      'ag-heading': {
        size: {
          width: `67%`,
          height: '12%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'text-transform': 'uppercase',
          'font-size': `${52 * scale}px`,
          'line-height': `${56 * scale}px`,
          'justify-content': 'center',
          top: `8%`,
          left: enableLogo ? `24.63%` : '50%',
          ...(enableLogo ? {} : { transform: 'translateX(-50%)', 'text-align': 'center' })

        }
      },

      'ag-wave': {
        size: {
          height: '27%',
          width: '100%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: '0',
          left: '0',
          'display': 'flex',
          'flex-direction': 'column',
          'justify-content': 'flex-end'
        },
        waveType: 'five'
      },

      'ce-subtitle': {
        value: 'Subtitles which are auto-filled from the transcript. It can extend to up to 2 lines.',
        size: {
          width: `79.62%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '39.2%',
          left: '8.9%',
          'font-size': '64px',
          'line-height': '1.2'
        }
      },

      'ag-node-11': {},
      'ag-node-12': {
        size: {
          height: `0`,
          width: `0`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'opacity': '0'
        }
      },
      'ag-node-13': {
        size: {
          height: `11.11%`,
          width: `11.11%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '8.42%',
          left: '8.9%'
        }
      }
    },

    portrait: {
      'ag-node-1': {
        size: {
          height: '33.33%',
          width: `100%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        cover: true,
        style: {
          'z-index': 3,
          top: 0,
          left: 0,
          background: accentColor || '#E55841'
        }
      },

      'ag-heading': {
        size: {
          width: `81.1%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'text-transform': 'uppercase',
          'font-size': `${72 * scale}px`,
          'line-height': `${78 * scale}px`,
          'justify-content': 'center',
          top: `17.7%`,
          left: enableLogo ? `8.7%` : '50%',
          ...(enableLogo ? {} : { transform: 'translateX(-50%)', 'text-align': 'center' })
        }
      },

      'ag-wave': {
        size: {
          height: '28.1%',
          width: '100%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: '0',
          left: '0',
          'display': 'flex',
          'flex-direction': 'column',
          'justify-content': 'flex-end'
        },
        waveType: 'five'
      },

      'ce-subtitle': {
        value: 'Subtitles which are auto-filled from the transcript. It can extend to up to 2 lines.',
        size: {
          width: `81.1%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '47.7%',
          left: '8.7%',
          'font-size': '72px',
          'line-height': '1.2'
        }
      },

      'ag-node-11': {},
      'ag-node-12': {
        size: {
          height: `0`,
          width: `0`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'opacity': '0'
        }
      },
      'ag-node-13': {
        size: {
          height: `9.63%`,
          width: `17.1%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          top: '4.8%',
          left: '8.7%'
        }
      }
    }
  },

  'six': {
    'landscape': {
      'ag-node-1': {
        size: {
          height: '63%',
          width: `100%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        cover: true,
        style: {
          'z-index': 3,
          top: 0,
          left: 0,
          background: '#fff'
        }
      },

      'ag-heading': {
        size: {
          width: `54%`,
          height: '12.1%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'text-transform': 'uppercase',
          'font-size': `${60 * scale}px`,
          'line-height': `${65 * scale}px`,
          'justify-content': 'center',
          top: `7.4%`,
          left: `6.14%`
        }
      },

      'ag-wave': {
        size: {
          height: `22.5%`,
          width: `68.7%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: `7.2%`,
          left: `6.14%`,
          'overflow-x': 'hidden'
        },
        waveType: 'six'
      },

      'ce-subtitle': {
        value: 'Subtitles which are auto-filled from the transcript. It can extend to up to 3 lines.',
        size: {
          width: `57.8%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'z-index': 5,
          top: '20.5%',
          left: '6.14%',
          'font-size': '64px',
          'line-height': '1.2'
        }
      },

      'ag-node-11': {},
      'ag-node-12': {
        size: {
          height: `41%`,
          width: `23%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 80.6,
          y: 68.2
        },
        style: {
          'z-index': 5
        }
      },
      'ag-node-13': {
        size: {
          height: `16.7%`,
          width: `9.4%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: '7.76%',
          right: '6.35%',
          'background-image': `url(/images/default/logo-light.png)`
        }
      }
    },

    square: {
      'ag-node-1': {
        size: {
          height: '65.2%',
          width: `100%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        cover: true,
        style: {
          'z-index': 3,
          top: '6.4%',
          left: 0,
          background: '#fff'
        }
      },

      'ag-heading': {
        size: {
          width: `87%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'text-transform': 'uppercase',
          'font-size': `${62 * scale}px`,
          'line-height': `${90 * scale}px`,
          top: `12.5%`,
          left: `6.2%`
        }
      },

      'ag-wave': {
        size: {
          height: `15.1%`,
          width: `56.6%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: `6.2%`,
          left: `6.2%`,
          'overflow-x': 'hidden'
        },
        waveType: 'six'
      },

      'ce-subtitle': {
        value: 'Subtitles which are auto-filled from the transcript. It can extend to up to 3 lines.',
        size: {
          width: `45.74%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'z-index': 5,
          top: '38.4%',
          left: '6.2%',
          'font-size': '48px',
          'line-height': '1.2'
        }
      },

      'ag-node-11': {},
      'ag-node-12': {
        size: {
          height: `33.15%`,
          width: `33.15%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 76.6,
          y: 50.5
        },
        style: {
          'z-index': 5
        }
      },
      'ag-node-13': {
        size: {
          height: `11.1%`,
          width: `11.1%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: '8.33%',
          right: '6.85%',
          'background-image': `url(/images/default/logo-light.png)`
        }
      }
    },

    portrait: {
      'ag-node-1': {
        size: {
          height: '80%',
          width: `100%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        cover: true,
        style: {
          'z-index': 3,
          top: 0,
          left: 0,
          background: '#fff'
        }
      },

      'ag-heading': {
        size: {
          width: `83.8%`,
          height: `8.33%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'text-transform': 'uppercase',
          'font-size': `${64 * scale}px`,
          'line-height': `${76 * scale}px`,
          top: `48.6%`,
          left: `8.15%`,
          'display': 'flex',
          'justify-content': 'center'
        }
      },

      'ag-wave': {
        size: {
          height: `12.4%`,
          width: `60.2%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: `3.8%`,
          left: `9.15%`,
          'overflow-x': 'hidden'
        },
        waveType: 'six'
      },

      'ce-subtitle': {
        value: 'Subtitles which are auto-filled from the transcript. It can extend to up to 3 lines.',
        size: {
          width: `83.8%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          'z-index': 5,
          top: '58.6%',
          left: '8.15%',
          'font-size': '64px'
        }
      },

      'ag-node-11': {},
      'ag-node-12': {
        size: {
          height: `33.4%`,
          width: `59.44%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 50,
          y: 75
        },
        style: {
          'z-index': 5,
          'border': `${32 * scale}px solid ${backgroundColor}`
        }
      },
      'ag-node-13': {
        size: {
          height: `7.81%`,
          width: `13.9%`
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        style: {
          bottom: '6.09%',
          right: '8.05%',
          'background-image': `url(/images/default/logo-light.png)`
        }
      }
    }
  }
})
