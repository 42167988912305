import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import store from '@/services/store'
import getAudiogramTemplate from './template'

const limitString = function(str, limit = 30) {
  if (str.length > limit) return `${str.slice(0, limit)}...`
  else return str
}

export const subtitleStyle = {
  video: () => ({
    'bottom': '24px',
    'padding': '4px 8px',
    'font-family': 'Roboto',
    'font-size': '48px',
    'font-weight': '400', // 700
    'font-style': 'normal', // italic
    'color': '#E2462C',
    'background': '#FFF',
    'border-radius': '5px',
    'display': 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    'z-index': 2,
    'width': '80%',
    'text-align': 'center'
  }),
  audiogram: (scale) => ({
    'z-index': 4,
    'font-family': 'Roboto',
    'font-size': '48px',
    'font-weight': '400', // 700
    'font-style': 'normal',
    color: '#000228',
    position: 'absolute',
    bottom: `${330 * scale}px`,
    left: `${100 * scale}px`
  })
}

export const transformFontSize = (style, scale = 1) => {
  let { 'font-size': fontSize, ...rest } = style
  if (scale && fontSize.includes('px')) fontSize = `${Number(fontSize.replace('px', '') * scale)}px`
  return { ...rest, 'font-size': fontSize }
}

export const getImageFromPath = (path) => new Promise(async (resolve) => {
  const imageCache = store.state.video.imageCache
  if (imageCache[path]) resolve(imageCache[path])
  else {
    const fileUrl = await firebase.storage().ref(path).getDownloadURL()
    const img = new Image()
    img.src = fileUrl
    img.addEventListener('load', function () {})
    store.commit('video/addImage', { [path]: fileUrl })
    resolve(fileUrl)
  }
})

export default {
  video: (scale) => ({
    elements: [
      {
        name: 'ce-video',
        id: 'ce-video-default',
        type: 'video/mp4',
        value: '',
        size: {
          height: '100%',
          width: '100%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        cover: true,
        style: {
          'z-index': 1
        },
        currentTime: 0,
        updateCount: 0
      },
      {
        name: 'ce-logo',
        id: 'ce-logo-default',
        value: '',
        type: 'image',
        size: {
          // height: '17.8%', // commenting out fixes weird aspect ratio issues
          width: '10%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 92,
          y: 88
        },
        style: {
          'z-index': 2
        }
      },
      {
        name: 'ce-subtitle',
        id: 'ce-subtitle-default',
        type: 'text',
        value: '',
        size: {},
        position: { // in percentage & relative from left bottom corner
          x: 50,
          y: 0
        },
        style: transformFontSize(subtitleStyle.video(), scale)
      }
    ]
  }),
  audiogram: async (scale, videoInfo, from = '') => {
    const {
      backgroundImage,
      coverArt,
      subtitleStyle: additionalSubtitleStyle,
      aspectRatio,
      template,
      accentColor,
      backgroundType,
      backgroundColor,
      logo,
      titleStyle,
      enableLogo,
      showSampleSubtitle
    } = { ...videoInfo }

    const tstyles = getAudiogramTemplate(scale, {
      accentColor,
      backgroundColor,
      enableLogo
    })[(template || 'one')][aspectRatio || 'landscape']

    const elements = [
      {
        name: 'ag-node-0',
        id: 'ag-node-0-default',
        value: '',
        type: 'node',
        size: {
          height: '100%',
          width: '100%'
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0
        },
        cover: true,
        style: {
          'z-index': 2,
          'background': backgroundColor || '#fff',
          ...(tstyles['ag-node-0'] ? tstyles['ag-node-0'].style : {})
        }
      },
      {
        name: 'ag-node-1',
        id: 'ag-node-1-default',
        value: '',
        type: 'node',
        size: {
          height: '100%',
          width: `100%`,
          ...tstyles['ag-node-1'].size
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0,
          ...tstyles['ag-node-1'].position
        },
        cover: true,
        style: {
          'z-index': 3,
          background: 'transparent',
          ...tstyles['ag-node-1'].style
        }
      },
      {
        name: 'ag-heading',
        id: 'ag-heading-default',
        value: limitString(videoInfo.heading || '', 50),
        type: 'heading',
        size: {
          width: `50%`,
          ...tstyles['ag-heading'].size
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0,
          ...tstyles['ag-heading'].position
        },
        cover: true,
        style: {
          'z-index': 4,
          'font-family': 'Gilroy',
          'font-weight': 600,
          'font-size': `${76 * scale}px`,
          display: 'flex',
          'flex-direction': 'column',
          position: 'absolute',
          top: `${100 * scale}px`,
          left: `${100 * scale}px`,
          ...tstyles['ag-heading'].style,
          ...titleStyle
        },
        separatorStyle: {
          // background: '#000228',
          // height: `${8 * scale}px`,
          // width: `${132 * scale}px`,
          // 'margin-top': `${48 * scale}px`
          display: 'none'
        }
      },
      {
        name: 'ag-wave',
        id: 'ag-wave-default',
        value: '',
        type: 'wave',
        size: {
          height: `${178 * scale}px`,
          width: `${492 * scale}px`,
          ...tstyles['ag-wave'].size
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0,
          ...tstyles['ag-wave'].position
        },
        cover: tstyles['ag-wave'].hasOwnProperty('cover') ? tstyles['ag-wave'].cover : true,
        style: {
          'z-index': 4,
          position: 'absolute',
          bottom: `${117 * scale}px`,
          left: `${100 * scale}px`,
          ...tstyles['ag-wave'].style
        },
        waveType: tstyles['ag-wave'].waveType || 'default',
        waveColor: accentColor
      },
      {
        name: 'ce-subtitle',
        id: 'ce-subtitle-default',
        type: 'text',
        value: showSampleSubtitle ? (tstyles['ce-subtitle'].value || 'Transcript will be here') : '',
        size: {
          width: `40%`,
          ...tstyles['ce-subtitle'].size
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0,
          ...tstyles['ce-subtitle'].position
        },
        style: transformFontSize({
          ...subtitleStyle.audiogram(scale),
          ...additionalSubtitleStyle,
          ...tstyles['ce-subtitle'].style
        }, scale),
        hidden: false
      }
    ]

    if (backgroundType === 'image' && backgroundImage && backgroundImage.url) {
      const fileUrl = await getImageFromPath(backgroundImage.url)

      elements.push({
        name: 'ag-node-11',
        id: 'ag-node-11-default',
        value: '',
        type: 'node',
        size: {
          height: '100%',
          width: '100%',
          ...tstyles['ag-node-11'].size
        },
        position: { // in percentage & relative from left bottom corner
          x: 0,
          y: 0,
          ...tstyles['ag-node-11'].position
        },
        cover: true,
        style: {
          'z-index': 2,
          'background-image': `url(${fileUrl})`,
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
          'background-position': 'center',
          ...tstyles['ag-node-11'].style
        }
      })
    }

    if (coverArt && coverArt.url) {
      const fileUrl = await getImageFromPath(coverArt.url)

      elements.push({
        name: 'ag-node-12',
        id: 'ag-node-12-default',
        value: '',
        type: 'node',
        size: {
          height: `${350 * scale}px`, // commenting out fixes weird aspect ratio issues
          width: `${350 * scale}px`,
          ...tstyles['ag-node-12'].size
        },
        position: { // in percentage & relative from left bottom corner
          x: 60,
          y: 50,
          ...tstyles['ag-node-12'].position
        },
        style: {
          'z-index': 4,
          'background-image': `url(${fileUrl})`,
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
          'background-position': 'center',
          'border-radius': `${32 * scale}px`,
          ...tstyles['ag-node-12'].style
        }
      })
    } else {
      elements.push({
        name: 'ag-node-12',
        id: 'ag-node-12-default',
        value: '',
        type: 'node',
        size: {
          height: `${350 * scale}px`, // commenting out fixes weird aspect ratio issues
          width: `${350 * scale}px`,
          ...tstyles['ag-node-12'].size
        },
        position: { // in percentage & relative from left bottom corner
          x: 60,
          y: 50,
          ...tstyles['ag-node-12'].position
        },
        style: {
          'z-index': 4,
          'background-image': `url(/images/default/cover.png)`,
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
          'background-position': 'center',
          'border-radius': `${32 * scale}px`,
          ...tstyles['ag-node-12'].style
        }
      })
    }

    if (logo && logo.url) {
      const fileUrl = await getImageFromPath(logo.url)

      elements.push({
        name: 'ag-node-13',
        id: 'ag-node-13-default',
        value: '',
        type: 'node',
        size: {
          height: `${180 * scale}px`, // commenting out fixes weird aspect ratio issues
          width: `${180 * scale}px`,
          ...tstyles['ag-node-13'].size
        },
        position: { // in percentage & relative from left bottom corner
          x: 60,
          y: 50,
          ...tstyles['ag-node-13'].position
        },
        style: {
          'z-index': 4,
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
          'background-position': 'center',
          'border-radius': `${24 * scale}px`,
          ...tstyles['ag-node-13'].style,
          ...(enableLogo ? { opacity: 1 } : { 'opacity': 0 }),
          'background-image': `url(${fileUrl})`
        }
      })
    } else {
      elements.push({
        name: 'ag-node-13',
        id: 'ag-node-13-default',
        value: '',
        type: 'node',
        size: {
          height: `${180 * scale}px`, // commenting out fixes weird aspect ratio issues
          width: `${180 * scale}px`,
          ...tstyles['ag-node-13'].size
        },
        position: { // in percentage & relative from left bottom corner
          x: 60,
          y: 50,
          ...tstyles['ag-node-13'].position
        },
        style: {
          'z-index': 4,
          'background-image': `url(/images/default/logo-dark.png)`,
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
          'background-position': 'center',
          'border-radius': `${24 * scale}px`,
          ...tstyles['ag-node-13'].style,
          ...(enableLogo ? { opacity: 1 } : { 'opacity': 0 })
        }
      })
    }

    return { elements }
  }
}
